import { useEffect } from 'react';
import { useWidget } from '../../WidgetContext';
import SectionHeading from '../../componets/ui/SectionHeading';
import Testimonial from '../../componets/ui/Testimonial';
import './Home.css';
import ReactGA from "react-ga"
import { useState } from "react";
import { Helmet } from 'react-helmet-async';


const testimonials = [
    {
        id:0,
        stars: 5,
        title:"Was So Amazing",
        description: "Chris made me have a beautiful experience. Looked after me well and got me drunk. Big love to all. Power hour lots of flower and tower",
        author: {
            image: '/img/avatar-placeholder.jpg',
            name: "John Doe"
        },
        date: "February 27, 2024 On Tripadvisor" 
    },
    {
        id:1,
        stars: 5,
        title:"Was So Amazing",
        description: "Chris made me have a beautiful experience. Looked after me well and got me drunk. Big love to all. Power hour lots of flower and tower",
        author: {
            image: '/img/avatar-placeholder.jpg',
            name: "John Doe"
        },
        date: "February 27, 2024 On Tripadvisor" 

    },
    {
        id:3,
        stars: 5,
        title:"Was So Amazing",
        description: "Chris made me have a beautiful experience. Looked after me well and got me drunk. Big love to all. Power hour lots of flower and tower",
        author: {
            image: '/img/avatar-placeholder.jpg',
            name: "John Doe"
        },
        date: "February 27, 2024 On Tripadvisor" 

    },
    {
        id:4,
        stars: 5,
        title:"Was So Amazing",
        description: "Chris made me have a beautiful experience. Looked after me well and got me drunk. Big love to all. Power hour lots of flower and tower",
        author: {
            image: '/img/avatar-placeholder.jpg',
            name: "John Doe"
        },
        date: "February 27, 2024 On Tripadvisor" 

    },
    {
        id:5,
        stars: 5,
        title:"Was So Amazing",
        description: "Chris made me have a beautiful experience. Looked after me well and got me drunk. Big love to all. Power hour lots of flower and tower",
        author: {
            image: '/img/avatar-placeholder.jpg',
            name: "John Doe"
        },
        date: "February 27, 2024 On Tripadvisor" 

    },
]
function triggerCheckout() {
    // Access the TicketingHub checkout widget
    var widget = window.THCheckout.getWidget('d70bb2ce-dd04-4752-b6ea-b3b5ea563052');

    // Open the checkout modal when the button is clicked
    widget.open();
}

// Add click event listener to the checkout button
export default function Home(){
    useEffect(() =>{
      ReactGA.pageview(window.location.pathname)
    }, [])
  const { loadWidget } = useWidget();

    return(
        <><Helmet>
            <title>City Pub Crawl</title>
            <meta name="description" content="Party with city pub crawl in krakow and enjoy the best night clubs in old town" />
            <link rel="canonical" href="/" />
        </Helmet>
        <main>
                <header className='jumbotron'>
                    <div className="overlay"></div>
                    <div className="content">
 
                        <h2>Start your Night with unlimited Drinks for an hour in first bar, Beer, Rum, whisky, Gin, Vodka and shots, Stag Groups are welcome Hens are welcome we also do private groups.<br /> <span> City Pub Crawl  we party we repeat</span></h2>
{/*                         <h2>Celebrate New Year's Eve in style with our epic Pub Crawl! Enjoy 2 hours of unlimited beer, rum, whiskey, vodka, gin, and shots for just 199PLN. Plus, get free entry to 3 exclusive clubs! <b />Don’t miss out on the ultimate party experience to ring in the new year!</h2>
                        <button type='button' onClick={loadWidget} className='btn open-book th-checkout-button' data-widget="d70bb2ce-dd04-4752-b6ea-b3b5ea563052">Book Now</button> */}
                    </div>
                </header>
                <section>
                    <SectionHeading heading={"We offer the following"} />
                    <div className="services">
                        <div className="container">
                            <div className="services-grid">
                                <div className="service">
                                    <h5>City Pub Crawl Offers</h5>
                                    <div className="service-icon">
                                        {/* <img src="/img/exclusive.png" alt="" /> */}
                                    </div>
                                    <h4 className="service-name">The VIP Treatment</h4>
                                    <p className="service-description">Avoid standing in lines at clubs and get in first</p>
                                </div>
                                <div className="service">
                                    <h5>City Pub Crawl Offers</h5>
                                    <div className="service-icon">
                                        {/* <img src="/img/beer.png" alt="" /> */}
                                    </div>
                                    <h4 className="service-name">Free Unlimited Drinks</h4>
                                    <p className="service-description">Start your night with free drinks for an hour in first bar, Vodka Rum beer whiskey Gin plus mixers and shoots plus ask for Mad dog shot</p>
                                </div>
                                <div className="service">
                                    <h5>City Pub Crawl Offers</h5>
                                    <div className="service-icon">
                                        {/* <img src="/img/ticket.png" alt="" /> */}
                                    </div>
                                    <h4 className="service-name">Free Entrance & Free Shots</h4>
                                    <p className="service-description">In each club get welcoming shots</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="call-for-action">
                        <h2>Book us now online or pay at the meeting point</h2>
                        <button onClick={loadWidget} className='btn cfa-btn checkoutButton'>Book Now</button>
                    </div>
                </section>
                {/* <section>
        <SectionHeading heading={"What do our customers say?"}/>
        <div className="testimonials">
            <div className="container">
                <div className="testimonials-wrapper">
                    {
                        testimonials.slice(0,4).map((item,index) =>(
                            <Testimonial key={index} testimonial={item}/>
                        ))
                    }
                </div>
                <button className='btn testimonial-btn'>Load More</button>
            </div>
        </div>
    </section> */}
                {/* <section>
        <SectionHeading heading={"The Crawl"}/>
        <div className="about">
            <div className="content">
                <h4>Nightlife Adventure in Krakow: Party, Drinks, and VIP Experience</h4>
                <p>Dive into the legendary nightlife of Krakow alongside an international crowd of fellow travelers from across the world and highly motivated party guides, experiencing the exhilarating POWER
                HOUR with unlimited drinks, including Polish beer, vodka, gin, whiskey, and rum, as we embark on a thrilling bar-hopping adventure through the heart of Europe's best-kept secret, visiting 4-
                5 of Krakow's most exceptional bars and clubs, and conclude the night at our final club with a separate VIP entrance, allowing us to skip the often-endless lines at the door and continue the
                festivities until at least 05:00 AM.</p>
            </div>
            <div className="content">
                <h4>Experience the best bars and nightlife in krakow on a leading pub crawl</h4>
                <p>Are you struggling for suggestions on where to party in Krakow? Or are you having a hard time picking which of Krakow’s fantastic pubs to visit? So join us on a pub crawl through some of
                Krakow’s top establishments and see where the night takes you! Why not visit many wonderful pubs in Krakow and spend an evening trying all the tasty beers and premium spirits on offer
                with our Krakow pub crawl? Krakow Animals Crawl is a leading nightlife experience provider in Poland taking you to the best bars. If you are looking for the best Krakow nightlife for the best
                prices, we can assure you your night with Krakow Animals Crawl will be a night to remember.</p>
                <p>We work with a multitude of customers from Hen nights to stag dos, birthday parties and general pub crawls. For anyone looking for a fun, memorable night out in Krakow, join Krakow
                Animals Crawl. On each pub crawl you will have a personal, experience guide to ensure the night is hyped and thriving. Not only do we include unlimited alcohol and free entry to the top 4
                leading clubs in Krakow with welcome shots on arrival, but we also include a magician along the pub crawl to mesmerise your night.</p>
            </div>
            <div className="content">
                <h4>What are the benefits of experiencing a pub crawl in Krakow ?</h4>
                <p>Dive into the legendary nightlife of Krakow alongside an international crowd of fellow travelers from across the world and highly motivated party guides, experiencing the exhilarating POWER
                HOUR with unlimited drinks, including Polish beer, vodka, gin, whiskey, and rum, as we embark on a thrilling bar-hopping adventure through the heart of Europe's best-kept secret, visiting 4-
                5 of Krakow's most exceptional bars and clubs, and conclude the night at our final club with a separate VIP entrance, allowing us to skip the often-endless lines at the door and continue the
                festivities until at least 05:00 AM.</p>
            </div>
        </div>
    </section>
    <section>
        <SectionHeading heading={"Our Partners"}/>
        <div className="partners">
            <div className="partners-wrapper">
                <div className="partner">
                    <img src="/img/partner1.png" alt="" />
                </div>
                <div className="partner">
                    <img src="/img/partner2.png" alt="" />
                </div>
                <div className="partner">
                    <img src="/img/partner3.png" alt="" />
                </div>
            </div>
        </div>
    </section> */}
            </main></>
    )
}
